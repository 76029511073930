<template>
  <q-card>
    <!-- Notif Dialog -->
    <q-dialog
      v-model="dialog.show"
      persistent
    >
      <q-card style="width: 300px">
        <q-card-section>
          <div>{{ dialog.message }}</div>
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn flat size="sm" label="Close" v-close-popup @click="changeToLogin ? changeMode() : null" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <div class="row justify-center">
      <q-item>
        <q-item-section class=" q-px-none">
            <q-img
              width="140px"
              :ratio="16/9"
              src="/logo-wide.png"
              spinner-color="primary"
              spinner-size="82px"
              fit="cover"
              class="rounded-borders"
            />
        </q-item-section>
      </q-item>
    </div>

    <q-separator />

    <q-card-section>
      <q-form
        ref="form"
        class="q-gutter-md"
      >
        <div class="row justify-between">
          <div class="col-5">
            <q-input
              v-if="!isLoginMode"
              v-model="payload.firstName"
              type="text"
              label="First Name"
              dense
              lazy-rules
              :rules="rules.firstName"
            />
          </div>
          <div class="col-5">
            <q-input
              v-if="!isLoginMode"
              v-model="payload.lastName"
              type="text"
              label="Last Name"
              dense
            />
          </div>
        </div>
        <div class="row justify-between">
          <div class="col-12">
            <q-input
              v-model="payload.email"
              type="text"
              label="Email"
              dense
              autocomplete="off"
              lazy-rules
              :rules="rules.email"
            />
          </div>
          <div :class="[ isLoginMode ? 'col-12' : 'col-5' ]">
            <q-input
              v-model="payload.password"
              :type="showPassword ? 'text': 'password'"
              label="Password"
              dense
              autocomplete="new-password"
              lazy-rules
              :rules="rules.password"
            >
              <template v-slot:append>
                <q-icon class="cursor-pointer" :name="showPassword ? 'visibility_off': 'visibility'" @click="showPassword = !showPassword" />
              </template>
            </q-input>
          </div>
          <div class="col-5">
            <q-input
              v-if="!isLoginMode"
              v-model="payload.passwordConfirm"
              :type="showPasswordConfirm ? 'text': 'password'"
              label="Password Confirmation"
              dense
              autocomplete="new-password"
              lazy-rules
              :rules="rules.passwordConfirm"
            >
              <template v-slot:append>
                <q-icon class="cursor-pointer" :name="showPasswordConfirm ? 'visibility_off': 'visibility'" @click="showPasswordConfirm = !showPasswordConfirm" />
              </template>
            </q-input>
          </div>
        </div>
      </q-form>
    </q-card-section>

    <q-separator />

    <q-card-actions justify="space-around">
      <div class="col text-left">
        {{ sayHai }} <span class="text-primary cursor-pointer" @click="changeMode">here</span>
      </div>
      <div class="col text-right">
        <q-btn outline size="sm" color="primary" @click="submit">{{ isLoginMode ? 'Login' : 'Register' }}</q-btn>
      </div>
    </q-card-actions>
  </q-card>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, getCurrentInstance, onMounted } from '@vue/runtime-core'
import { capitalize } from '../utils/functions'

export default {
  props: {
    mode: {
      type: String,
      default: 'login'
    }
  },
  setup (props, ctx) {
    const instance = getCurrentInstance()
    const self = instance.appContext.config.globalProperties

    const payload = ref({
      address: '',
      bio: '',
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirm: '',
      imageUrl: ''
    })

    const dialog = ref({
      show: false,
      message: ''
    })

    const changeToLogin = ref(false)

    const rules = ref({
      email: [(val) => (val && val.length > 0) || 'Email required'],
      password: [(val) => (val && val.length > 0) || 'Password required'],
      passwordConfirm: [(val) => (val && val.length > 0 && val === payload.value.password) || 'Password confirmation not match'],
      firstName: [(val) => (val && val.length > 0) || 'First name required']
    })

    const isLoginMode = computed(() => props.mode.toLowerCase() === 'login')
    const sayHai = computed(() => isLoginMode.value ? 'Doesn\'t have an account yet? Register ' : 'Have an account? Login ')

    const reset = () => {
      payload.value = {
        address: '',
        bio: '',
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        passwordConfirm: '',
        imageUrl: ''
      }
    }

    const changeMode = () => {
      // eslint-disable-next-line no-unused-expressions
      instance?.refs.form.resetValidation()
      ctx.emit('changeMode', isLoginMode.value ? 'register' : 'login')
      reset()
    }

    onMounted(() => {
      payload.value.email = ''
      payload.value.password = ''
    })

    const submit = async () => {
      const valid = await instance?.refs.form.validate()

      if (!valid) return

      let res
      if (isLoginMode.value) {
        res = await self.$api.auth.login(payload.value)
        if (res?.success) {
          location.href = '/dashboard'
        }
      } else {
        res = await self.$api.auth.register(payload.value)
        if (res?.success) {
          dialog.value.show = true
          dialog.value.message = 'Register success, you can login with your credentials.'
          changeToLogin.value = true
        }
      }

      if (!res?.success) {
        dialog.value.show = true
        dialog.value.message = capitalize(res?.message ?? 'Unknown error occured.')
      }
    }

    return {
      payload,
      submit,
      changeMode,
      isLoginMode,
      sayHai,
      showPassword: ref(false),
      showPasswordConfirm: ref(false),
      rules,
      dialog,
      changeToLogin
    }
  }
}
</script>
