<template>
  <div class="q-pa-md-0 doc-container">
    <div class="row justify-center">
      <div class="col-xs-10 col-sm-6 col-md-4 col-lg-3 self-center text-center">
        <form-login :mode="formMode" @changeMode="changeMode" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import FormLogin from '../components/formLogin.vue'

export default {
  name: 'Home',
  components: {
    FormLogin
  },
  setup (props) {
    const formMode = ref('login')

    const changeMode = (newMode) => {
      formMode.value = newMode
    }

    return {
      formMode,
      changeMode
    }
  }
}
</script>

<style lang="scss" scoped>
.row {
  height: 100vh;
}
</style>
